import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext';

export interface ChatMessage {
  id: number;
  sender: string;
  message: string;
  timestamp: string;
  created_by_child?: number | null | string;
  is_seen: boolean;
  is_child_seen?: boolean;
  task: number;
}

interface AskInstructorModalProps {
  showModal: boolean;
  handleModalClose: () => void;
  taskToAsk: number;
  child_id?: number;
  child: string;
  userRole: 'student' | 'instructor';
}

const AskInstructorModal: React.FC<AskInstructorModalProps> = ({
  showModal,
  handleModalClose,
  taskToAsk,
  child_id,
  userRole,
  child,
}) => {
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [userInput, setUserInput] = useState<string>('');
  const { token, userInfo,user } = useContext(AuthContext) || {};

  useEffect(() => {
    if (showModal && taskToAsk) fetchMessages();
    console.log('taskToAsk',taskToAsk);
    const intervalId = setInterval(() => {
      if (showModal && taskToAsk) fetchMessages();
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `https://codeoceantech.pythonanywhere.com/api/chat-messages/?task_id=${taskToAsk}`
      );
      const messages = response.data;
      // console.log(messages);
      if (userRole === 'instructor') {
        
        setChatMessages(messages.filter((message:any)=>  message.child_name === child));
      }else {
        setChatMessages(messages.filter((message:any)=>  {
          if (message.child_name === user ) {
            return message
          }
        }));

      }
      handleUnseenMessages(messages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleUnseenMessages = async (messages: ChatMessage[]) => {
    const unseenMessages = messages.filter(
      (msg) =>
        (userRole === 'instructor' && !msg.is_seen && msg.sender !== 'instructor') ||
        (userRole === 'student' && !msg.is_child_seen && msg.sender === 'instructor')
    );

    if (unseenMessages.length > 0) {
      try {
        await markMessagesAsSeen(unseenMessages);
      } catch (error) {
        console.error('Error marking messages as seen:', error);
      }
    }
  };

  const markMessagesAsSeen = async (messages: ChatMessage[]) => {
    const delay = (seconds: number) =>
      new Promise((resolve) => setTimeout(resolve, seconds * 1000));

    for (const message of messages) {
      await axios.put(
        `https://codeoceantech.pythonanywhere.com/api/chat-messages/${message.id}/`,
        userRole === 'instructor'
          ? { is_seen: true, task: taskToAsk }
          : { is_child_seen: true, task: taskToAsk }
      );
      await delay(1);
    }

    setChatMessages((prevMessages) =>
      prevMessages.map((msg) =>
        messages.some((unseenMsg) => unseenMsg.id === msg.id)
          ? { ...msg, is_seen: true, is_child_seen: true }
          : msg
      )
    );
  };

  const handleSendMessage = async () => {
    if (userInput.trim()) {
      const newMessage: ChatMessage = {
        id: Date.now(),
        sender: userRole,
        message: userInput,
        timestamp: new Date().toISOString(),
        created_by_child: child,
        is_seen: false,
        task: taskToAsk,
      };

      setChatMessages((prevMessages) => [...prevMessages, newMessage]);
      setUserInput('');

      try {
        await axios.post(
          `https://codeoceantech.pythonanywhere.com/api/chat-messages/`,
          newMessage
        );
        if (userRole === 'student') simulateInstructorResponse(userInput);
      } catch (error:any) {
        console.error('Error sending message:', error.response?.data || error.message);
      }
    }
  };

  const simulateInstructorResponse = (userMessage: string) => {
    setTimeout(() => {
      const instructorReply: ChatMessage = {
        id: Date.now() + 1,
        sender: 'instructor',
        message: `جاري اخطار المدرس ${userMessage}`,
        timestamp: new Date().toISOString(),
        is_seen: false,
        task: taskToAsk,
      };
      setChatMessages((prevMessages) => [...prevMessages, instructorReply]);
    }, 2000);
  };

  const formatTimestamp = (isoString: string) => {
    const date = new Date(isoString);
    return date.toLocaleString('ar-EG', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity z-50 ${
        showModal ? 'opacity-100 visible' : 'opacity-0 invisible'
      }`}
    >
      <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-lg">
        <button className="text-black text-xl font-bold" onClick={handleModalClose}>
          &times;
        </button>

        <h2 className="text-green-600 text-xl font-semibold mb-4 text-center">
          {userRole === 'student' ? 'اسأل المدرس' : 'Elmohandes Conversations'}
        </h2>

        <div className="border border-gray-300 rounded-lg p-4 mb-4 h-64 overflow-y-auto flex flex-col" dir="rtl">
          {chatMessages.length === 0 ? (
            <p className="text-gray-500 text-center">لا توجد رسائل حتى الآن.</p>
          ) : (
            chatMessages.map((chat) => (
              <div
                key={chat.id}
                className={`mb-2 p-2 rounded-lg ${
                  chat.sender === 'student' ? 'bg-blue-100 self-end' : 'bg-green-100 self-start'
                } text-right`}
              >
                <p className="font-bold">{chat.sender === 'student' ? 'الطالب' : 'المدرس'}</p>
                <p>{chat.message}</p>
                <p className="text-xs text-gray-500">{formatTimestamp(chat.timestamp)}</p>
              </div>
            ))
          )}
        </div>

        <textarea
          className="w-full p-2 rounded-lg border border-gray-300 focus:ring focus:ring-green-500 mb-2"
          dir="rtl"
          placeholder="اكتب رسالتك هنا..."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          rows={3}
        />

        <div className="mt-2 flex justify-end" dir="rtl">
          <button className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition" onClick={handleSendMessage}>
            إرسال
          </button>
        </div>
      </div>
    </div>
  );
};

export default AskInstructorModal;
