import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const ChatComponent: React.FC = () => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<string[]>([]);
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('AuthContext is not available');
  }

  useEffect(() => {
    const token = authContext.token?.access;
    console.log('Attempting WebSocket connection with token:', token);

    const ws = new WebSocket(`ws://127.0.0.1:8000/ws/chat/?token=${token}`);

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => handleIncomingMessage(event);
    ws.onerror = (error) => console.error('WebSocket encountered an error:', error);
    ws.onclose = (event) => console.log('WebSocket connection closed:', event);

    setSocket(ws);

    return () => {
      console.log('Cleaning up WebSocket connection');
      ws.close();
    };
  }, [authContext.token?.access]);

  const handleIncomingMessage = (event: MessageEvent) => {
    try {
      const data = JSON.parse(event.data);
      console.log('Message received:', data);
      setMessages((prevMessages) => [...prevMessages, data.message]);
    } catch (error) {
      console.error('Error parsing message:', event.data, error);
    }
  };

  const sendMessage = () => {
    if (socket && message.trim()) {
      const messageData = { message };
      console.log('Sending message:', messageData);
      socket.send(JSON.stringify(messageData));
      setMessage(''); // Clear input after sending
    } else {
      console.warn('Cannot send message: WebSocket is not connected or message is empty');
    }
  };

  return (
    <div className="min-h-[60vh]">
      <ul>
        {messages.map((msg, idx) => (
          <li key={idx}>{msg}</li>
        ))}
      </ul>
      <input
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message here..."
      />
      <button onClick={sendMessage}>Send</button>
    </div>
  );
};

export default ChatComponent;
