import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useNavigate, useSearchParams } from 'react-router-dom';

const AutoLogin: React.FC = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const username = searchParams.get('username');
    const password = searchParams.get('password');

    if (username && password) {
      autoLogin(username, password);
    } else {
      setError('اسم المستخدم أو كلمة المرور مفقودة في معلمات URL.');
      setLoading(false);
    }
  }, [searchParams]);

  const autoLogin = async (username: string, password: string) => {
    setLoading(true);
    setError('');

    try {
      const response = await fetch('https://codeoceantech.pythonanywhere.com/api/accounts/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Check user type and navigate accordingly
        if (data.user_info.user_type === 'student') {
          data.user_info['id'] = data.child.id;
          login(
            data.token,
            data.child.name,
            data.user_info,
            data.parent,
            data.user_info.user_type,
            data.child.is_active
          );
          navigate('/profile/student');
        } else {
          login(
            data.token,
            data.user_info.username,
            data.user_info,
            data.parent,
            data.user_info.user_type,
            true
          );
          navigate(data.user_info.user_type === 'instructor' ? '/profile/instructor' : '/profile');
        }
      } else {
        setError(data.detail || 'فشل تسجيل الدخول. يرجى التحقق من صحة البيانات.');
      }
    } catch (error) {
      setError('حدث خطأ أثناء محاولة تسجيل الدخول. يرجى المحاولة لاحقاً.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-white text-center">
      {loading ? (
        <div className="text-[#ff5c2f] text-2xl font-bold">
          جاري تسجيل الدخول كـ {searchParams.get('username') || 'المستخدم'}...
        </div>
      ) : error ? (
        <div className="text-red-500">{error}</div>
      ) : null}
    </div>
  );
};

export default AutoLogin;
