// WhatsAppButton.tsx
import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { useAuth } from '../hooks/useAuth';

const WhatsAppButton: React.FC = () => {
    const [message, setMessage] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const { token, userInfo } = useAuth(); // جلب المعلومات من سياق المصادقة
    const [phoneNumber, setPhoneNumber] = useState('+966538059677');

    useEffect(()=>{
        if(userInfo ){
          setPhoneNumber('+201097430973');
        }
      })
    

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (message.trim()) {
            const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl, '_blank');
            setMessage(''); // Clear the message after sending
        }
    };

    return (
        <div
            className="fixed bottom-5 right-5 z-50"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {!isHovered && (
                <a
                    href="#"
                    className="flex items-center justify-center w-16 h-16 bg-green-500 rounded-full shadow-lg transition-transform transform hover:scale-105 duration-300"
                    onClick={(e) => {
                        e.preventDefault();
                        if (message.trim()) {
                            const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
                            window.open(whatsappUrl, '_blank');
                            setMessage(''); // Clear the message after sending
                        }
                    }}
                >
                    <FaWhatsapp className="w-10 h-10 text-white" />
                </a>
            )}
            {isHovered && (
                <div className="mt-2 flex flex-col items-start transition-opacity duration-300">
                    <div className="p-2 bg-white shadow-lg rounded-lg flex items-center space-x-2 mb-2 opacity-100 transition-opacity duration-300">
                        <span className="text-lg text-gray-700">👋 مرحبًا بك! 😊</span>
                    </div>
                    <form
                        onSubmit={handleSubmit}
                        className="p-2 bg-white shadow-lg rounded-lg flex items-center space-x-2 transition-transform duration-300"
                    >
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type a message..."
                            className="border border-gray-300 rounded-md p-1 focus:outline-none focus:ring focus:ring-green-400 duration-300"
                        />
                        <button
                            type="submit"
                            className="bg-green-500 text-white px-2 py-1 rounded-md transition-colors hover:bg-green-600 duration-300"
                        >
                            Send
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default WhatsAppButton;
