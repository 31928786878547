// src/components/AboutUs.tsx

import React, { useEffect } from 'react';
import {
  AboutHero,
  AboutMessage,
  AboutTarget,
  AboutVision,
  AboutWhy,
} from '../../assets/inedx';
import ImageWithLoader from '../../components/ImageWithLoader';

// Main AboutUs Component
const AboutUs: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white shadow flex flex-col justify-end items-center">
      {/* Header Section */}
      <HeaderSection />

      {/* Content Sections */}
      <ContentSections />

      {/* Latest Section */}
      <LatestSection />
    </div>
  );
};

// Header Section Component

// Header Section Component
const HeaderSection: React.FC = () => {
    return (
      <header className="w-full h-auto relative">
        <div className="w-full h-auto flex justify-center items-center">
          <div className="w-full h-auto relative">
            {/* Background Layers */}
            <BackgroundLayers />
  
            {/* Header Image */}
            <img
              className="w-full h-auto max-h-[600px] object-cover "
              src={AboutHero}
              alt="Header Image - Elmohandes Academy"
            />
          </div>
        </div>
  
        {/* "من نحن" Box */}
        <div className="w-11/12 md:w-3/4 lg:w-2/5 px-6 py-4 md:px-16 md:py-8 bg-gray-300 rounded-3xl flex flex-col justify-center items-end gap-6 md:gap-12 absolute top-24 md:top-32 lg:top-40 right-4 md:right-16 lg:right-40">
          <h1 className="text-right text-black text-4xl md:text-5xl lg:text-6xl font-black font-Cairo leading-tight">
            من نحن
          </h1>
          <p className="text-right text-black text-base md:text-lg lg:text-xl font-black font-Cairo leading-relaxed">
            تأسست أكاديمية المهندس قبل ثلاث سنوات بهدف تمكين الطلاب من اكتساب المهارات التكنولوجية والبرمجية الأساسية. نسعى إلى خلق بيئة تعليمية إبداعية تعزز التفكير المنطقي وتشجع الطلاب على استكشاف عوالم التكنولوجيا والبرمجة.
          </p>
        </div>
      </header>
    );
  };
  

// Background Layers Component
const BackgroundLayers: React.FC = () => {
  return (
    <>
      <div className="w-full h-full absolute top-0 bg-[#44afe80a] opacity-75"></div>
      <div className="w-full h-full absolute top-0 bg-[#44afe809] opacity-50"></div>
    </>
  );
};

// Content Sections Component
const ContentSections: React.FC = () => {
  return (
    <section
      className="w-full flex flex-col justify-start items-center gap-20 
      md:gap-40 px-4 md:px-8 lg:px-16 mt-5"
      dir="rtl"
    >
      <Section
        title="رؤيتنا"
        description="رؤيتنا هي أن نصبح روادًا في تعليم الطلاب المهارات الرقمية المستقبلية، 
          نهدف إلى بناء جيل من المفكرين المبدعين والمهندسين الرقميين القادرين على 
          مواكبة التقدم التكنولوجي السريع."
        imageSrc={AboutVision}
        reverse={false}
      />
      <Section
        title="رسالتنا"
        description="نسعى إلى تقديم تعليم عالي الجودة في مجال البرمجة والتكنولوجيا، 
          من خلال أساليب تعليمية تفاعلية مبتكرة تعزز قدرات الطلاب في التفكير 
          النقدي والإبداعي."
        imageSrc={AboutMessage}
        reverse={true}
      />
      <Section
        title="أهدافنا"
        description={`الدورات التدريبية: نقدم مجموعة متنوعة من الدورات التدريبية المصممة 
          خصيصًا للطلاب في مجالات البرمجة، تصميم الألعاب، الروبوتات، وتعلم 
          استخدام الحاسب الآلي.\nالمستويات: نوفر برامج تدريبية لمختلف المستويات، 
          من المبتدئين إلى المتقدمين، مع تخصيص مسارات تعليمية تناسب كل فئة عمرية.\nالأعمار: نقدم برامج تعليمية تناسب الفئات العمرية بين 6 و16 عامًا، مما 
          يضمن تقديم المحتوى المناسب لكل مرحلة تطورية.`}
        imageSrc={AboutTarget}
        reverse={false}
      />
      <Section
        title="لماذا نحن"
        description={`الخبرة:
        لدينا فريق من المدربين المتخصصين في مجالات التكنولوجيا والتعليم، 
        بخبرة واسعة في تعليم الطلاب، مما يضمن جودة وفعالية العملية التعليمية.\n
        البيئة التعليمية:
        نحرص على توفير بيئة تعليمية آمنة، تفاعلية، وملهمة تساعد الطلاب على 
        التعلم بطريقة ممتعة وتحفزهم على الاستكشاف.\n
        النتائج:
        على مدار السنوات الثلاث الماضية، ساهمنا في تطوير مهارات مئات الطلاب 
        في مجال البرمجة، حيث استطاعوا بناء تطبيقات وألعاب بسيطة بأنفسهم.`}
        imageSrc={AboutWhy}
        reverse={true}
      />
    </section>
  );
};

// Unified Section Props Interface
interface SectionProps {
  title: string;
  description: string;
  imageSrc: string;
  reverse: boolean;
}

// Section Component
const Section: React.FC<SectionProps> = ({ title, description, imageSrc, reverse }) => {
  return (
    <div
      className={`w-full flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-20 ${
        reverse ? 'lg:flex-row-reverse' : ''
      }`}
    >
      {/* Text Content */}
      <div className="w-full lg:w-1/2 flex flex-col justify-start items-start gap-4">
        <div className="flex items-center gap-3">
          <div className="w-18 h-0.5 bg-[#fbd784]"></div>
          <div className="text-right text-[#fbd784] text-sm md:text-base 
            font-normal font-Plaster uppercase tracking-wider">
            ELMOHANDES ACADEMY
          </div>
        </div>
        <h2 className="text-right text-[#3b642e] text-3xl md:text-4xl lg:text-5xl 
          font-semibold font-Lora">
          {title}
        </h2>
        <p className="text-right text-[#3b642e] text-sm md:text-base lg:text-lg 
          font-normal font-Plaster leading-relaxed whitespace-pre-line">
          {description}
        </p>
      </div>

      {/* Image */}
      <div className="w-full lg:w-1/2 flex justify-center items-center">
        <ImageWithLoader
          src={imageSrc}
          alt={`${title} Image`}
          className="w-full max-w-md md:max-w-lg lg:max-w-xl h-auto object-cover rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

// Latest Section Component
const LatestSection: React.FC = () => {
  return (
    <section className="w-full flex flex-col lg:flex-row justify-start items-start 
      px-4 md:px-8 lg:px-16 py-10 md:py-20 gap-10 lg:gap-20">
      {/* Latest Info */}
      <div className="w-full lg:w-1/3 flex flex-col justify-start items-start gap-6">
        <div className="flex flex-col items-start gap-2">
          <div className="text-[#3b642e] text-2xl font-light font-Inter tracking-wide">
            Latest
          </div>
          <div className="w-1 bg-[#00eac7] h-6"></div>
        </div>
        <p className="text-[#bccbd6] text-base font-light font-Karla leading-relaxed">
          Learn about Aleph Zero technology,<br />watch tutorials, catch up with<br />event recaps, and more.
        </p>
        <button className="flex items-center gap-3 bg-[#0f3236] text-[#03d1b5] 
          px-6 py-3 rounded-full hover:bg-[#0d2c33] transition duration-300">
          <span className="text-center text-sm font-medium font-Inter">
            Go to YouTube channel
          </span>
          {/* You can add an icon here if needed */}
        </button>
      </div>

      {/* Latest Cards */}
      <div className="w-full lg:w-2/3 flex flex-wrap justify-start items-start gap-6">
        {Array.from({ length: 5 }).map((_, index) => (
          <LatestCard
            key={index}
            imageSrc="https://via.placeholder.com/274x156"
            title="Without being SNARKy, what's coming up for"
            date="Jan 14, 2023"
          />
        ))}
      </div>
    </section>
  );
};

// Latest Card Props Interface
interface LatestCardProps {
  imageSrc: string;
  title: string;
  date: string;
}

// Latest Card Component
const LatestCard: React.FC<LatestCardProps> = ({ imageSrc, title, date }) => {
  return (
    <article className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-1 rounded-sm border border-white 
      bg-white shadow-lg">
      <ImageWithLoader
        src={imageSrc}
        alt={`${title} Thumbnail`}
        className="w-full h-auto object-cover rounded-t-sm"
      />
      <div className="p-4 flex flex-col justify-start items-start">
        <h3 className="text-[#3b642e] text-lg font-medium font-Inter leading-snug">
          {title}
        </h3>
        <div className="flex items-center gap-2 mt-2">
          {/* You can add an icon here if needed */}
          <span className="text-[#3c652f] text-xs font-light font-Karla">
            {date}
          </span>
        </div>
      </div>
    </article>
  );
};

export default AboutUs;
