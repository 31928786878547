import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { AuthContext } from "../../context/AuthContext";
import { logo } from "../../assets/inedx";
import { Assistant } from "@mui/icons-material";

interface NavItem {
  label: string;
  path: string;
  icon: React.ReactNode;
}

// Define navItems
const navItems: NavItem[] = [
  { label: "Profile", path: "/profile", icon: <PersonIcon /> },
  { label: "Home", path: "/", icon: <HomeIcon /> },
];

const SideNav: React.FC = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is not available");
  }

  const { logout, userInfo } = authContext;
  const isChild = userInfo?.user_type === "student";
  const isInstructor = userInfo?.user_type === "instructor";

  // Update profile paths based on user role
  if (isChild) {
    navItems[0].path = "/profile/student";
  } else if (isInstructor) {
    navItems[0].path = "/instructor/dashboard";
    if (!navItems.some(item => item.label === "Activities")) {
      navItems.push({
        label: "Activities",
        path: "/instructor/activities",
        icon: <Assistant />,
      });
    }
  }

  const handleLogout = () => {
    logout();
  };

  const whatsappMessage = encodeURIComponent(
    `مرحباً بك في فريق دعم Elmohandes Academy! نحن هنا لمساعدتك في إدارة حسابك والرد على استفساراتك.`
  );

  const whatsappLink = `https://wa.me/201097430973?text=${whatsappMessage}`;

  const bgColor = isChild ? "bg-yellow-50" : "bg-gray-100";
  const navItemBgColor = isChild ? "bg-yellow-200 hover:bg-yellow-300" : "bg-gray-300 hover:bg-gray-400";
  const iconColor = isChild ? "text-yellow-700" : "text-gray-700";
  const logoutBgColor = isChild ? "bg-yellow-500 hover:bg-yellow-600" : "bg-gray-400 hover:bg-gray-500";
  const textColor = isChild ? "text-yellow-900" : "text-black";

  return (
    <div className={`flex flex-col ${bgColor} transition-colors duration-300 max-sm:w-[40px] min-h-screen`}>
      {/* Logo Section */}
      <div className={`flex items-center justify-center p-4 ${isChild ? "bg-yellow-400" : "bg-gray-800"}`}>
        <img src={logo} alt="Logo" className={`w-32 max-sm:w-[60px!important] h-auto`} />
      </div>

      {/* Navigation Items */}
      <List className="flex-1">
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton component={Link} to={item.path} className={`flex items-center p-3 rounded-md ${navItemBgColor}`}>
              <ListItemIcon className={`text-lg ${iconColor}`}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} className={`ml-3 hidden md:block ${iconColor}`} />
            </ListItemButton>
          </ListItem>
        ))}

        {/* WhatsApp Support */}
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href={whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex items-center p-3 rounded-md ${navItemBgColor}`}
          >
            <ListItemIcon className={`text-lg ${iconColor}`}>
              <WhatsAppIcon />
            </ListItemIcon>
            <ListItemText primary="WhatsApp Support" className={`ml-3 hidden md:block ${iconColor}`} />
          </ListItemButton>
        </ListItem>
      </List>

      {/* Logout Button */}
      <div className="p-4">
        <IconButton onClick={handleLogout} className={`w-full rounded-md ${logoutBgColor}`}>
          <LogoutIcon className={textColor} />
          <span className={`ml-2 hidden md:block text-xs ${textColor}`}>
            {isChild ? "العودة للمستخدم الأساسي" : "Logout"}
          </span>
        </IconButton>
      </div>
    </div>
  );
};

export default SideNav;
