import React from 'react';

const TermsAndConditions: React.FC = () => {
  return (
    <div className="p-6 bg-white text-black" dir='rtl'>
      <h1 className="text-3xl font-bold mb-6 text-center">الشروط والأحكام</h1>
      <p className="mb-4 text-center">
        تاريخ السريان: {new Date().toLocaleDateString('ar-SA')}
      </p>
      <p className="mb-4 text-lg">
        مرحبًا بك في أكاديميتنا! يُرجى قراءة الشروط والأحكام التالية بعناية قبل استخدام خدماتنا.
        من خلال الوصول إلى الموقع أو استخدام خدماتنا، توافق على الالتزام بهذه الشروط. إذا كنت لا توافق على هذه الشروط، يُرجى عدم استخدام خدماتنا.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">1. التعريفات</h2>
      <div className="mb-4">
        <p className="font-medium">الأكاديمية:</p>
        <p>تشير إلى المهندس الأكاديمي.</p>
      </div>
      <div className="mb-4">
        <p className="font-medium">المستخدم:</p>
        <p>تشير إلى أي شخص يقوم بالتسجيل أو استخدام خدمات الأكاديمية.</p>
      </div>
      <div className="mb-4">
        <p className="font-medium">الخدمات:</p>
        <p>تشير إلى جميع الدورات، المواد التعليمية، والمحتويات المقدمة من الأكاديمية.</p>
      </div>

      <h2 className="text-2xl font-semibold mt-6 mb-4">2. التسجيل والاستخدام</h2>
      <p className="mb-4">
        يجب على المستخدمين التسجيل للحصول على حساب لاستخدام خدمات الأكاديمية. 
        يتعين على المستخدم تقديم معلومات صحيحة وكاملة عند التسجيل.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">3. سياسة الاسترداد</h2>

      <h3 className="font-semibold mt-4">3.1 فترة الاسترداد</h3>
      <p className="mb-4">
        يمكن للمستخدمين تقديم طلب لاسترداد المبلغ المدفوع خلال الأسبوع الأول من تاريخ الاشتراك.
      </p>

      <h3 className="font-semibold mt-4">3.2 عملية الطلب</h3>
      <p className="mb-4">
        يجب على المستخدمين تقديم طلب الاسترداد عبر واتساب الدعم على الرقم: [رقم الواتساب].
        يتطلب الطلب تقديم معلومات مثل الاسم، تاريخ الاشتراك، وسبب الاسترداد.
      </p>

      <h3 className="font-semibold mt-4">3.3 مراجعة الطلب</h3>
      <p className="mb-4">
        سيتم مراجعة طلب الاسترداد خلال 3 أيام عمل من تاريخ استلام الطلب.
      </p>

      <h3 className="font-semibold mt-4">3.4 معالجة الاسترداد</h3>
      <p className="mb-4">
        سيتم معالجة الاسترداد في غضون يوم واحد من الموافقة على الطلب.
      </p>

      <h3 className="font-semibold mt-4">3.5 استثناءات الاسترداد</h3>
      <p className="mb-4">
        لا تُقبل طلبات الاسترداد في الحالات التالية:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>إذا تم استخدام المحتوى أو الموارد التعليمية المقدمة.</li>
        <li>إذا تم تقديم الطلب بعد مرور الأسبوع الأول من الاشتراك.</li>
        <li>في حالة عدم الالتزام بشروط استخدام الأكاديمية.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">4. حقوق الملكية الفكرية</h2>
      <p className="mb-4">
        جميع المحتويات المقدمة من الأكاديمية، بما في ذلك النصوص، الصور، والبرمجيات، محمية بموجب حقوق الملكية الفكرية.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">5. التعديلات على الشروط</h2>
      <p className="mb-4">
        تحتفظ الأكاديمية بالحق في تعديل هذه الشروط في أي وقت. سيتم إخطار المستخدمين بأي تغييرات عبر البريد الإلكتروني أو من خلال الموقع.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">6. الاتصال بالدعم</h2>
      <p className="mb-4">
        لأي استفسارات أو لمزيد من المعلومات، يُرجى الاتصال بفريق الدعم عبر:
      </p>
      {/* <ul className="list-disc ml-6 mb-4">
        <li>واتساب: [رقم الواتساب]</li>
        <li>البريد الإلكتروني: [البريد الإلكتروني الخاص بالدعم]</li>
        <li>الهاتف: [رقم الهاتف]</li>
        <li>نموذج الاتصال: [رابط إلى نموذج الاتصال]</li>
      </ul> */}

      <h2 className="text-2xl font-semibold mt-6 mb-4">7. القوانين المعمول بها</h2>
      <p className="mb-4">
        تخضع هذه الشروط لقوانين المملكة العربية السعودية.
      </p>
    </div>
  );
};

export default TermsAndConditions;
