import React from "react";
import { Link } from 'react-router-dom'; 
import { FaFacebookF, FaInstagram, FaSnapchatGhost, FaYoutube } from "react-icons/fa"; // Import React Icons

const Footer: React.FC = () => {
  return (
    <footer className="bg-[#043131] text-white py-12 px-4 md:px-8 lg:px-16" dir="rtl">
      <div className="container mx-auto flex flex-col gap-12">
        {/* Top Section */}
        <div className="flex flex-wrap gap-12">
          {/* Company Info */}
          <div className="flex-1 max-w-xs">
            <div className="text-3xl font-normal font-['Nico Moji'] mb-4 text-right">
              EL MOHANDES
            </div>
            <p className="text-base font-bold font-['Cairo'] text-right">
              انضم إلينا في رحلة التميز وتطوير المهارات، وكن جزءاً من النجاح
              المستمر مع "المهندس". نحن هنا لدعمك في كل خطوة على طريق تحقيق
              أحلامك وبناء مستقبل مهني واعد.
            </p>
          </div>

          {/* Quick Links */}
          <div className="flex-1 max-w-xs">
            <div className="text-xl font-bold font-['Open Sans'] mb-4 text-right">
              عن الشركة
            </div>
            <ul className="space-y-3 text-lg font-normal font-['Open Sans'] text-right">
              <li><Link to="/about" className="hover:underline">من نحن</Link></li> {/* About Us */}
              <li><Link to="/terms-and-conditions" className="hover:underline">الشروط والأحكام</Link></li> {/* Terms & Conditions */}
              <li><Link to="/privacy-policy" className="hover:underline">سياسة الخصوصية</Link></li> {/* Privacy Policy */}
              <li>الحجوزات</li>
              <li>الخدمات</li>
            </ul>
          </div>

          {/* Support */}
          <div className="flex-1 max-w-xs">
            <div className="text-xl font-bold font-['Open Sans'] mb-4 text-right">
              الدعم
            </div>
            {/* <p className="text-lg font-normal font-['Open Sans'] text-right">
              جدة - المملكة العربية السعودية
            </p> */}
            <p className="text-lg font-normal font-['Open Sans'] text-right">
              info@elmohandes.org
            </p>
            <div className="flex space-x-3 mt-4 justify-end">
              {/* Social Media Icons */}
              <a href="https://www.facebook.com/profile.php?id=100094472067462&mibextid=ZbWKwL" aria-label="Facebook" className="w-8 h-8 text-white">
                <FaFacebookF size={24} />
              </a>
              <a href="https://www.snapchat.com/add/elmohandeskids" aria-label="Snapchat" className="w-8 h-8 text-white">
                <FaSnapchatGhost size={24} />
              </a>
              <a href="https://youtube.com/@user-wc1sc8dl7x?feature=shared" aria-label="YouTube" className="w-8 h-8 text-white">
                <FaYoutube size={24} />
              </a>
              <a href="https://www.instagram.com/mo.ayman81?igsh=MWZzNXBkeGRjNXlpNw==" aria-label="Instagram" className="w-8 h-8 text-white">
                <FaInstagram size={24} />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div>

        <div className="text-sm text-center text-gray-400">
          © 2024 EL MOHANDES. All Rights Reserved.
        </div>
        {/* Bottom Section */}
        <div className="text-sm text-center text-gray-400">
        powered by codeocean
        </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
