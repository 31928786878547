import React from 'react';

const Policies: React.FC = () => {
  return (
    <div className="p-6 bg-white text-black" dir='rtl'>
      <h1 className="text-2xl font-bold mb-4">سياسات الاستخدام</h1>
      <p className="mb-4">
        مرحبًا بك في أكاديميتنا! يُرجى قراءة السياسات التالية بعناية قبل استخدام خدماتنا.
      </p>

      <h2 className="text-xl font-semibold mt-4">1. أنواع المستخدمين</h2>
      <h3 className="font-semibold">1.1 ولي الأمر</h3>
      <p className="mb-4">
        يجب على ولي الأمر تقديم بيانات صحيحة حول ابنه والتأكد من تحديث المعلومات المتعلقة بالمجموعة التي يشترك بها.
      </p>

      <h3 className="font-semibold">1.2 الطالب</h3>
      <p className="mb-4">
        يجب على الطالب الحفاظ على معلومات دقيقة حول المجموعة المشتركة التي ينتمي إليها، وكذلك الالتزام بالقوانين والأحكام الخاصة بالأكاديمية.
      </p>

      <h3 className="font-semibold">1.3 المدرس</h3>
      <p className="mb-4">
        يجب على المدرس تقديم بيانات دقيقة حول كل حصة تعليمية يتم تعيينه لها، ويجب عليه الحفاظ على معلومات الطلاب المسجلين في كل حصة.
      </p>

      <h2 className="text-xl font-semibold mt-4">2. جمع البيانات واستخدامها</h2>
      <p className="mb-4">
        نقوم بجمع البيانات الشخصية المتعلقة بالمستخدمين بهدف تحسين تجربة التعلم وضمان توفير خدمات أفضل. 
        يتعهد المستخدمون بتقديم معلومات دقيقة وصحيحة.
      </p>

      <h2 className="text-xl font-semibold mt-4">3. خصوصية البيانات</h2>
      <p className="mb-4">
        تحرص الأكاديمية على حماية بيانات المستخدمين وفقًا للقوانين المعمول بها. 
        لن يتم مشاركة المعلومات الشخصية مع أطراف ثالثة دون موافقة المستخدم.
      </p>

      <h2 className="text-xl font-semibold mt-4">4. التعديلات على السياسات</h2>
      <p className="mb-4">
        تحتفظ الأكاديمية بالحق في تعديل هذه السياسات في أي وقت. سيتم إخطار المستخدمين بأي تغييرات عبر البريد الإلكتروني أو من خلال الموقع.
      </p>

      <h2 className="text-xl font-semibold mt-4">5. الاتصال بالدعم</h2>
      <p className="mb-4">
        لأي استفسارات أو لمزيد من المعلومات، يُرجى الاتصال بفريق الدعم عبر:
      </p>
      {/* <ul className="list-disc ml-6 mb-4">
        <li>واتساب: [رقم الواتساب]</li>
        <li>البريد الإلكتروني: [البريد الإلكتروني الخاص بالدعم]</li>
        <li>الهاتف: [رقم الهاتف]</li>
        <li>نموذج الاتصال: [رابط إلى نموذج الاتصال]</li>
      </ul> */}
    </div>
  );
};

export default Policies;
