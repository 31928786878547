import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';

const SessionProfileAttendance: React.FC = () => {
  const { sessionId, courseId } = useParams<{ sessionId: string; courseId: string }>(); // Get sessionId and courseId from URL
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [statusMessage, setStatusMessage] = useState<string>('🤔 نبدأ في تسجيل حضورك...');
  const navigate = useNavigate();

  if (!authContext) {
    throw new Error('AuthContext is not available');
  }

  const { token, userInfo } = authContext;

  useEffect(() => {
    const handleAddAttendance = async () => {
      if (!token || !userInfo?.username) {
        setError('يجب تسجيل الدخول أولا');
        return;
      }
      setLoading(true);
      setStatusMessage('🔄 جاري تسجيل حضورك، يرجى الانتظار...');
      try {
        await axios.post(
          ` https://codeoceantech.pythonanywhere.com/api/sessions/${sessionId}/attendance/add/`,
          { child_id: userInfo?.username },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        setLoading(false);
        setStatusMessage('✅ تم تسجيل الحضور بنجاح! يتم الآن تحويلك إلى الجلسة...');
        setTimeout(() => {
          navigate(`/profile/student/course/${courseId}/session/${sessionId}`);
        }, 2000); // Wait for 2 seconds before redirecting to give a smoother experience
      } catch (err) {
        console.error('Error adding attendance:', err);
        setError('فشل في تسجيل الحضور. حاول مرة أخرى.');
        setLoading(false);
      }
    };

    handleAddAttendance(); // Automatically add attendance when the component mounts
  }, [token, userInfo?.username, sessionId, courseId, navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-yellow-100 text-yellow-800 p-4">
      <div className="text-center">
        <span className="text-6xl">👋</span>
        <h1 className="text-2xl font-bold my-4">مرحباً {userInfo?.username}!</h1>
        <p className="text-lg mb-4">{statusMessage}</p>

        {loading && (
          <div className="mt-6">
            <span className="text-4xl animate-spin">⏳</span>
            <p>يرجى الانتظار قليلاً...</p>
          </div>
        )}

        {error && <p className="mt-4 text-red-500">{error}</p>}
      </div>
    </div>
  );
};

export default SessionProfileAttendance;
