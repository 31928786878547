import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define types for parent and student details
interface ParentDetails {
  username: string;
  password: string;
  confirmPassword: string;
  email: string;
  phoneNumber: string;
  country_code?: string;
  numChildren: number;
}

interface StudentDetails {
  name: string;
  age: number;
  experience: string;
}

interface FormContextType {
  parentDetails: ParentDetails;
  setParentDetails: React.Dispatch<React.SetStateAction<ParentDetails>>;
  studentDetails: StudentDetails[];
  setStudentDetails: React.Dispatch<React.SetStateAction<StudentDetails[]>>;
}

// Create a context with default values
const FormContext = createContext<FormContextType | undefined>(undefined);

// Create a provider component
export const FormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [parentDetails, setParentDetails] = useState<ParentDetails>({
    username: '',
    password: '',
    confirmPassword: '',
    country_code: '',
    email: '',
    phoneNumber: '',
    numChildren: 0,
  });

  const [studentDetails, setStudentDetails] = useState<StudentDetails[]>([]);

  return (
    <FormContext.Provider value={{ parentDetails, setParentDetails, studentDetails, setStudentDetails }}>
      {children}
    </FormContext.Provider>
  );
};

// Custom hook to use the FormContext
export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};
