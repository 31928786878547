import React, { createContext, useState, useEffect, ReactNode, useCallback } from 'react';
import axios from 'axios';

interface Child {
  name: string;
  age: number;
  experience: string;
  clear_username: string;
}

interface Parent {
  user: number;
  children: Child[];
}

interface UserInfo {
  id?: number;
  user_id?: number;
  username: string;
  phone_number: string;
  email: string;
  user_type: string;
}

interface Token {
  access: string;
  refresh: string;
}

interface AuthContextProps {
  user: string | null;
  token: Token | null;
  parent: Parent | null;
  userInfo: UserInfo | null;
  role: string | null;
  loading: boolean;
  is_active: boolean;
  login: (token: Token, user: string, userInfo: UserInfo, parent: Parent, role: string,is_active:boolean) => void;
  logout: () => void;
  refreshToken: () => Promise<void>;
  checkUserActive: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<string | null>(null);
  const [token, setToken] = useState<Token | null>(null);
  const [parent, setParent] = useState<Parent | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [is_active, setIs_active] = useState<boolean>(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    const storedParent = localStorage.getItem('parent');
    const storedUserInfo = localStorage.getItem('user_info');
    const storedRole = localStorage.getItem('role');
    const storedIs_active = localStorage.getItem('is_active');
  
    if (storedToken && storedToken !== 'undefined') {
      setToken(JSON.parse(storedToken));
    }
    if (storedUser && storedUser !== 'undefined') {
      setUser(storedUser);
    }
    if (storedParent && storedParent !== 'undefined') {
      setParent(JSON.parse(storedParent));
    }
    if (storedUserInfo && storedUserInfo !== 'undefined') {
      setUserInfo(JSON.parse(storedUserInfo));
    }
    if (storedRole && storedRole !== 'undefined') {
      setRole(storedRole);
    }
    if (storedIs_active && storedIs_active === 'true') {
      setIs_active(true);
    }
  
    setLoading(false);
  }, []);
  

  const login = (token: Token, userData: string, userInfo: UserInfo, parentData: Parent, userRole: string,is_active:boolean) => {
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', userData);
    localStorage.setItem('parent', JSON.stringify(parentData));
    localStorage.setItem('user_info', JSON.stringify(userInfo));
    localStorage.setItem('role', userRole);
    localStorage.setItem('is_active', `${is_active}`);
    setToken(token);
    setUser(userData);
    setParent(parentData);
    setUserInfo(userInfo);
    setRole(userRole);
    setIs_active(is_active)
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('parent');
    localStorage.removeItem('user_info');
    localStorage.removeItem('role');
    setToken(null);
    setUser(null);
    setParent(null);
    setUserInfo(null);
    setRole(null);
    setIs_active(false)

  };

  const refreshToken = useCallback(async () => {
    try {
      const refresh = localStorage.getItem('refresh_token');
      if (!refresh) throw new Error('No refresh token available');

      const response = await axios.post('/api/token/refresh/', { refresh });
      const newToken = response.data.access;

      const updatedToken = {
        access: newToken,
        refresh: refresh,
      };

      localStorage.setItem('token', JSON.stringify(updatedToken));
      setToken(updatedToken);
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
    }
  }, [logout]);

  useEffect(() => {
    if (token) {
      const refreshInterval = setInterval(() => {
        refreshToken();
      }, 24 * 24 * 60 * 60 * 1000); // Refresh every 24 days
  
      return () => clearInterval(refreshInterval);
    }
  }, [token, refreshToken]);
  // Updated function to check if user is activated and set is_active state
  const checkUserActive = useCallback(async () => {
    if (!token) {
      setIs_active(false); // Not logged in
      return;
    }

    try {
      const response = await axios.get('https://codeoceantech.pythonanywhere.com/api/accounts/subscription/check/', {
        headers: {
          Authorization: `Bearer ${token.access}`,
        },
      });

      // Assuming the response has a field "is_active"
      const activeStatus = response.data.has_active_subscription;
      setIs_active(activeStatus); // Update is_active based on response
    } catch (error) {
      console.error('Error checking user activation status:', error);
      logout(); // Optional: log out on error
      setIs_active(false); // Reset active status on error
    }
  }, [token, logout]);

  return (
    <AuthContext.Provider value={{ user, token, parent, userInfo, role, is_active, loading, login, logout, refreshToken, checkUserActive }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
