import React from 'react';

interface AlertProps {
  type: 'info' | 'success' | 'warning' | 'error';
  message: string;
  visible: boolean;
  onClose: () => void; // Callback to handle closing
}

const alertStyles = {
  info: 'bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4',
  success: 'bg-green-100 border-l-4 border-green-500 text-green-700 p-4',
  warning: 'bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4',
  error: 'bg-red-100 border-l-4 border-red-500 text-red-700 p-4',
};

const AlertComponent: React.FC<AlertProps> = ({ type, message, visible, onClose }) => {
  return (
    <div
      className={`transition-all duration-500 ease-in-out transform relative ${
        visible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'
      } ${alertStyles[type]}`}
      style={{ maxHeight: visible ? '200px' : '0', overflow: 'hidden' }}
      role="alert"
    >
      <p className="font-bold">
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </p>
      <p>{message}</p>
      
      {/* Close button */}
      <button
        onClick={onClose}
        className="absolute top-0 right-0 p-2"
        aria-label="Close"
      >
        <svg className="w-6 h-6 text-gray-700 hover:text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </div>
  );
};

export default AlertComponent;
