import React, { useState, useEffect, useContext } from 'react';
import { FaSpinner, FaCheck, FaSearch, FaSync } from 'react-icons/fa';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import AskInstructorModal from './components/AskInstructorModal';

interface Activity {
  id: number;
  title: string;
  description: string;
  timestamp: string;
  is_seen: boolean;
  is_loading: boolean;
  activity_type: string;
  child_name: string;
  child_id: number;
  task_id: number;
}

const Activities: React.FC = () => {
  const [actions, setActions] = useState<Activity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentActionIndex, setCurrentActionIndex] = useState<number>(0);
  const [titleFilter, setTitleFilter] = useState<string>('');
  const [seenFilter, setSeenFilter] = useState<string>('all');
  const [filteredActions, setFilteredActions] = useState<Activity[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedChild, setSelectedChild] = useState<{ name: string, id: number } | null>(null);
  const [taskToAsk, setTaskToAsk] = useState<number | null>(null);
  const auth = useContext(AuthContext);

  const fetchActivities = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get<Activity[]>('https://codeoceantech.pythonanywhere.com/api/activities/', {
        headers: {
          Authorization: `Bearer ${auth?.token?.access}`,
        },
      });

      const fetchedActions = response.data.map(action => ({
        ...action,
        is_loading: false,
      }));

      setActions(fetchedActions);
      setFilteredActions(fetchedActions);
    } catch (error) {
      console.error('Error fetching activities:', error);
      setError('Failed to load activities. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const filterActions = () => {
    let filtered = actions;

    if (titleFilter) {
      filtered = filtered.filter(action =>
        action.title.toLowerCase().includes(titleFilter.toLowerCase())
      );
    }

    if (seenFilter !== 'all') {
      const isSeen = seenFilter === 'seen';
      filtered = filtered.filter(action => action.is_seen === isSeen);
    }

    setFilteredActions(filtered);
  };

  const markAsSeen = async (index: number) => {
    const actionToMark = filteredActions[index];

    if (!actionToMark) return;

    setFilteredActions(prevActions =>
      prevActions.map((action, idx) =>
        idx === index ? { ...action, is_loading: true } : action
      )
    );

    try {
      await axios.put(`https://codeoceantech.pythonanywhere.com/api/activities/${actionToMark.id}/`, {
        title: actionToMark.title,
        description: actionToMark.description,
        is_seen: true,
      }, {
        headers: {
          Authorization: `Bearer ${auth?.token?.access}`,
        },
      });

      setFilteredActions(prevActions =>
        prevActions.map((action, idx) =>
          idx === index ? { ...action, is_loading: false, is_seen: true } : action
        )
      );
    } catch (error) {
      console.error('Error marking activity as seen:', error);
      setError('Failed to mark activity as seen. Please try again later.');
    } finally {
      setCurrentActionIndex(prevIndex => prevIndex + 1);
    }
  };

  const handleOpenChat = (childName: string, childId: number, taskId: number) => {
    setSelectedChild({ name: childName, id: childId });
    setTaskToAsk(taskId);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedChild(null);
    setTaskToAsk(null);
  };

  useEffect(() => {
    fetchActivities();
  }, []);

  useEffect(() => {
    const fetchData = () => {
      fetchActivities();
    };

    fetchData();
    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    filterActions();
  }, [titleFilter, seenFilter, actions]);

  useEffect(() => {
    if (currentActionIndex < filteredActions.length && !filteredActions[currentActionIndex]?.is_seen) {
      const timeoutId = setTimeout(() => {
        markAsSeen(currentActionIndex);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [currentActionIndex, filteredActions]);

  return (
    <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Instructor Activities</h2>
      
      <div className="bg-white shadow-md rounded-lg p-6 mb-4">
        <div className="flex gap-4 mb-4">
          <div className="relative flex-grow">
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
            <input
              type="text"
              placeholder="Search by title"
              value={titleFilter}
              onChange={(e) => setTitleFilter(e.target.value)}
              className="border rounded pl-10 p-2 w-full"
            />
          </div>
          <select
            value={seenFilter}
            onChange={(e) => setSeenFilter(e.target.value)}
            className="border rounded p-2"
          >
            <option value="all">All</option>
            <option value="seen">Seen</option>
            <option value="unseen">Unseen</option>
          </select>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        {loading ? (
          <div className="flex flex-col items-center justify-center h-48">
            <FaSpinner className="animate-spin text-blue-500 text-3xl" />
            <p className="mt-2 text-gray-700">Loading...</p>
          </div>
        ) : error ? (
          <div className="flex flex-col items-center justify-center h-48 text-center">
            <p className="text-red-500 mb-2">{error}</p>
            <button onClick={fetchActivities} className="text-blue-500 hover:underline flex items-center">
              <FaSync className="mr-2" /> Retry
            </button>
          </div>
        ) : filteredActions.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-48">
            <p className="text-gray-700">No activities match your filters.</p>
          </div>
        ) : (
          <ul className="space-y-4">
            {filteredActions.map((action, index) => (
              <li
                key={action.id}
                className="flex items-start border-b pb-4 last:border-b-0 hover:bg-gray-50 transition-colors duration-150"
              >
                <div className="flex-shrink-0">
                  <span className="inline-block h-8 w-8 rounded-full bg-blue-500 text-white text-lg font-semibold flex items-center justify-center">
                    {action.id}
                  </span>
                </div>
                <div className="ml-4 flex-grow">
                  <h3 className="text-lg font-semibold text-gray-700">{action.title}</h3>
                  <p className="text-sm text-gray-500">{action.description}</p>
                  <span className="text-xs text-gray-400">{action.timestamp}</span>
                  <div className="text-sm mt-1">
                    {action.is_seen ? (
                      <span className="text-green-400 font-semibold">Seen</span>
                    ) : (
                      <span className="text-red-400 font-semibold">Unseen</span>
                    )}
                  </div>
                  {action.activity_type === 'message' && (
                    <button
                      onClick={() => handleOpenChat(action.child_name, action.child_id, action.task_id)}
                      className="text-blue-500 mt-2 hover:underline"
                    >
                      Open Chat
                    </button>
                  )}
                </div>
                <div className="ml-4 flex items-center">
                  {action.is_loading ? (
                    <FaSpinner className="animate-spin text-blue-500" />
                  ) : action.is_seen ? (
                    <FaCheck className="text-green-400" />
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {showModal && selectedChild && taskToAsk && (
        <AskInstructorModal
          showModal={showModal}
          child={selectedChild.name}
          child_id={selectedChild.id}
          handleModalClose={handleModalClose}
          taskToAsk={taskToAsk}
          userRole={"instructor"}
        />
      )}
    </div>
  );
};

export default Activities;
